<template>
  <router-view v-if="isRouter"/>
</template>
<script setup>
import requestUtil from '@/util/request'
import store from '@/store'
import {ref, watch, nextTick, provide, inject} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import {onMounted, onUnmounted, onBeforeUnmount} from 'vue';
import {ElMessage} from "element-plus"
import {decryptData, encryptData} from "@/util/decryptData";

const route = useRoute();
const router = useRouter();
const whitePath = ['/login', '/index', '/', '/register', '/resetPassword']

/**
 * 引入刷新 刷新路由
 */
const isRouter = ref(true)
const reload = () => {
  isRouter.value = false
  nextTick(() => {
    isRouter.value = true
  })
}
provide("reload", reload)
// 引入
// import { inject } from 'vue'
// const reload = inject("reload")
// 使用
// reload()
// 引入刷新时 引入上方即可

////////////↓↓↓↓↓↓↓↓ cvAuthorith cvAuthorith cvAuthorith cvAuthorith cvAuthorith/////////////////////////////////////////////////////////////////////////////////////////
////////////↓↓↓↓↓↓↓↓ cvAuthorith cvAuthorith cvAuthorith cvAuthorith cvAuthorith/////////////////////////////////////////////////////////////////////////////////////////
////////////↓↓↓↓↓↓↓↓ cvAuthorith cvAuthorith cvAuthorith cvAuthorith cvAuthorith/////////////////////////////////////////////////////////////////////////////////////////
const callCount = ref(0);
const lastCallTime = ref(0);
const inCooldown = ref(false); // 冷却器 请求次数过多冻结该请求
const threshold = 2; // 定义阈值，例如2次
const timeLimit = 1000; // 定义时间限制，例如1000毫秒（1秒）
const cooldownPeriod = 3000; // 冷却期，例如3000毫秒（3秒）
const checkInterval = 2000; // 定义检查间隔，例如每2000毫秒检查一次 添加一次监听
const total = ref(0)
// 复制粘贴触发事件
const preventCopyPaste = async (event) => {
  // 获取cv权限
  if (!store.state.systemAuthority.cvAuthority) {
    total.value = total.value + 1
    if (inCooldown.value) {
      event.preventDefault();
      return;
    }
    // 每个三次失败提醒一次开通vip
    if (total.value % 3 == 0) {
      // 跳转vip页面 全局变量 cv Authority Value
      global.cvAuthorityValue = true;
      requestUtil.WaitTime(500, toVipView)
      total.value = 0;
    }
    const currentTime = Date.now();
    if (currentTime - lastCallTime.value < timeLimit) {
      callCount.value += 1;
      if (callCount.value > threshold) {
        ElMessage.error("请稍后再试")
        inCooldown.value = true;
        setTimeout(() => inCooldown.value = false, cooldownPeriod);
        return;
      }
    } else {
      callCount.value = 1;
    }
    lastCallTime.value = currentTime;
    ElMessage.error("复制/粘贴为Vip功能")
    event.preventDefault();
  } else {
    // 复制成功
    if (inCooldown.value) {
      return;
    }
    const currentTime = Date.now();
    if (currentTime - lastCallTime.value < timeLimit) {
      callCount.value += 1;
      if (callCount.value > threshold) {
        ElMessage.error("请勿重复复制/粘贴")
        inCooldown.value = true;
        setTimeout(() => inCooldown.value = false, cooldownPeriod);
        return;
      }
    } else {
      callCount.value = 1;
    }
    lastCallTime.value = currentTime;
    ElMessage.success("尊敬的vip用户,复制/粘贴成功!")
  }
};
// 跳转vip页面
const toVipView = () => {
  router.replace("/vip")
}


// 检测复制粘贴监听是否被手动改拆除
const AddListeners = () => {
  // 检查并添加监听器的逻辑
  document.addEventListener('copy', preventCopyPaste, true);
  document.addEventListener('paste', preventCopyPaste, true);
};

// 检测复制粘贴监听是否被手动改拆除
const RemoveListeners = () => {
  // 检查并添加监听器的逻辑
  document.removeEventListener('copy', preventCopyPaste, true);
  document.removeEventListener('paste', preventCopyPaste, true);
};
////////////↑↑↑↑↑↑↑ cvAuthorith cvAuthorith cvAuthorith cvAuthorith cvAuthorith/////////////////////////////////////////////////////////////////////////////////////////
////////////↑↑↑↑↑↑↑ cvAuthorith cvAuthorith cvAuthorith cvAuthorith cvAuthorith/////////////////////////////////////////////////////////////////////////////////////////
////////////↑↑↑↑↑↑↑ cvAuthorith cvAuthorith cvAuthorith cvAuthorith cvAuthorith/////////////////////////////////////////////////////////////////////////////////////////


////////////↓↓↓↓↓↓↓↓ sleepAuthorith sleepAuthorith sleepAuthorith sleepAuthorith sleepAuthorith /////////////////////////////////////////////////////////////////////////////////////////
////////////↓↓↓↓↓↓↓↓ sleepAuthorith sleepAuthorith sleepAuthorith sleepAuthorith sleepAuthorith /////////////////////////////////////////////////////////////////////////////////////////
////////////↓↓↓↓↓↓↓↓ sleepAuthorith sleepAuthorith sleepAuthorith sleepAuthorith sleepAuthorith /////////////////////////////////////////////////////////////////////////////////////////
const timer = ref(null);

const startTimerLinster = () => {
  requestUtil.WaitTime(1000 * 60 * 15, clickSleep)
};
// 鼠标有动静就调用
const resetTimer = () => {
  clearTimeout(timer.value);
  startTimerLinster();
};
const clickSleep =async () => {
  await store.dispatch('setSleep',true)
}


////////////↑↑↑↑↑↑↑ sleepAuthorith sleepAuthorith sleepAuthorith sleepAuthorith sleepAuthorith /////////////////////////////////////////////////////////////////////////////////////////
////////////↑↑↑↑↑↑↑ sleepAuthorith sleepAuthorith sleepAuthorith sleepAuthorith sleepAuthorith /////////////////////////////////////////////////////////////////////////////////////////
////////////↑↑↑↑↑↑↑ sleepAuthorith sleepAuthorith sleepAuthorith sleepAuthorith sleepAuthorith /////////////////////////////////////////////////////////////////////////////////////////

// 页面渲染时渲染
onMounted(async () => {
  AddListeners()
  // 选然后每隔两秒给一次监听
  setInterval(RemoveListeners, checkInterval);
  setInterval(AddListeners, checkInterval);
  // 锁屏检测
  window.addEventListener('mousemove', resetTimer);
  window.addEventListener('keydown', resetTimer);
  // 设置睡眠状态
  // 有token再设置睡眠权限
  if(sessionStorage.getItem("token") == ""  || sessionStorage.getItem("token") == null){
    let token = await decryptData(sessionStorage.getItem("token"));
  }
  startTimerLinster();
});

onUnmounted(() => {
  // 销毁锁屏
  window.removeEventListener('mousemove', resetTimer);
  window.removeEventListener('keydown', resetTimer);
  clearTimeout(timer.value);
});
// 卸载页面时渲染
onBeforeUnmount(() => {
  RemoveListeners()
});
// 路由跳转后
// url处切换菜单 实时更新tab菜单 没有则添加 有则跳转
watch(route, (to, from) => {
  // console.log("【当前跳转路径】" + to.path+ "\t\t【当前跳转路径名】" + to.name  )
  if (whitePath.indexOf(to.path) === -1) {
    // console.log("【新增TopTab路径为】" + to.path)
    let obj = {
      name: to.name,
      path: to.path
    }
    store.commit("ADD_TABS", obj)
  }
}, {deep: true, immediate: true})


</script>

<style>
:root {
  --transition-layout: 0.5s; /* 定义全局的过渡时间变量 */
}

html, body, #app {
  height: 100%;
}

.app-container {
  padding: 20px
}
</style>
